














import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import Fab from '@/components/Fab.vue';
import { ProfileRow } from '@/components/settings';

import { Profile } from '@/models';
import { ProfileService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

@Component({
  components: {
    Fab,
    ProfileRow,
  },
})
export default class ProfileSettingsPage extends Vue {
  loading: boolean = false;
  profiles: Profile[] = [];

  async created(): Promise<void> {
    this.loading = true;
    this.profiles = await ProfileService.all();
    this.loading = false;
  }

  async updateDefault(id: number): Promise<void> {
    try {
      await ProfileService.default(id);
      this.profiles = this.profiles.map((e) => {
        e.isDefault = e.id === id;
        return e;
      });
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.default-profile'),
        unwrapError(e),
      );
    }
  }

  onDelete(profile: Profile): void {
    this.profiles = this.profiles.filter((e) => e.id !== profile.id);
  }

  async newProfile(): Promise<void> {
    await this.$router.push({
      name: 'settings-profile-edit',
      params: { profileId: 'new' },
    });
  }
}
